// export const baseurl = "http://172.16.1.247:2000/"; // local

export const baseurl = "https://node-walkandearn.mobiloitte.io/"; // stagin
// export const baseurl = "https://082e-182-71-75-106.ngrok-free.app/"; // Local

let user = `${baseurl}api`;
let authDetail = `${baseurl}api/user`;
let admin = `${user}/admin`;
let kyc = `${user}/kyc`;
let staticContent = `${user}/static_content`;
let brand = `${user}/brand`;
let report = `${user}/reportPost`;
let subadmin = `${user}/subAdmin`;
let products = `${user}/product`;
let faq = `${baseurl}api/faq`;
let contest = `${baseurl}api/contest`;
let order = `${baseurl}api/order`;
let howToWork = `${baseurl}api/howToWork`;
let payment = `${baseurl}api/payment`;
const ApiConfigs = {
  // admin Login

  emailForgotPassword: `${authDetail}/forgotPassword`,
  changePassword: `${authDetail}/changePassword`,
  login: `${authDetail}/loginUser`,
  verifyOTPEmail: `${authDetail}/verifyOtp`,
  resetPassword: `${authDetail}/resetPassword`,
  resendOTP: `${authDetail}/resendOtp`,
  //admin
  dashboard: `${admin}/dashboard`,
  listUser: `${admin}/listUser`,
  deleteUser: `${admin}/deleteUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  viewAdminProfile: `${authDetail}/profile`,
  listBank: `${admin}/listBank`,
  addBank: `${admin}/addBank`,
  editBank: `${admin}/editBank`,
  deleteBank: `${admin}/deleteBank`,
  acitavateBank: `${admin}/acitavateBank`,
  editProfile: `${authDetail}/editProfile`,
  depositeList: `${admin}/depositeList`,
  approveDepositRequest: `${admin}/approveDepositRequest`,
  activityLogHistory: `${admin}/activityLogHistory`,

  //kyc
  listKyc: `${kyc}/kycList`,
  approveKyc: `${kyc}/approveRejectKYC`,
  rejectKyc: `${kyc}/approveRejectKYC`,

  //staticContent
  staticContentList: `${staticContent}/listStaticContent`,
  editStaticContent: `${staticContent}/editStaticContent`,

  //skills category
  listSkill: `${staticContent}/howToPlayCategoryList`,
  addAdminSkill: `${staticContent}/addHowToPlayCategory`,
  deleteSkill: `${staticContent}/deleteHowToPlayCategory`,
  editSkill: `${staticContent}/editHowToPlayCategory`,

  // how to play
  // listHowToPlay: `${staticContent}/howToPlayList`,
  listHowToPlay: `${staticContent}/howToPlayListWithCategory`,
  addHowToPlay: `${staticContent}/addHowToPlay`,
  deleteHowToPlay: `${staticContent}/deleteHowToPlay`,
  editHowToPlay: `${staticContent}/editHowToPlay`,
  viewHowToPlay: `${staticContent}/viewHowToPlay`,
  //report
  reportList: `${report}/reportList`,
  deleteReport: `${report}/deleteReport`,
  blockPost: `${report}/blockPost`,

  //category
  listCategory: `${admin}/getAllBannnersPagination`,
  addCategory: `${admin}/addBanner`,
  editCategory: `${admin}/updateBanner`,
  deleteCategory: `${admin}/deleteBanner`,

  //sub Admin
  addSubAdmin: `${subadmin}/addSubAdmin`,
  updateSubAdmin: `${subadmin}/updateSubAdmin`,

  //brand management
  listBrand: `${brand}/listBrand`,
  addBrand: `${brand}/createBrand`,
  deleteBrand: `${brand}/deleteBrand`,
  editBrand: `${brand}/updateBrand`,
  listAllBrand: `${brand}/listAllBrand`,

  //Product
  listProducts: `${products}/listProduct`,
  viewProducts: `${products}/viewProduct`,
  addProducts: `${products}/addProduct`,
  deleteProduct: `${products}/deleteProduct`,
  editProducts: `${products}/editProduct`,
  addOrganizationType: `${products}/addOrganizationType`,
  updateOrganizationType: `${products}/updateOrganizationType`,
  blockUnblockProduct: `${products}/blockUnblockProduct`,
  // faq's
  faqList: `${faq}/faqList`,
  addFAQ: `${faq}/addFAQ`,
  editFAQ: `${faq}/editFAQ`,
  viewFAQ: `${faq}/viewFAQ`,
  deleteFAQ: `${faq}/deleteFAQ`,
  stepCount: `${user}/step/userSteps`,

  // Contest Management
  listcontest: `${contest}/listContestCategory`,
  deleteContestCategory: `${contest}/deleteContestCategory`,
  deleteContest: `${contest}/deleteContest`,
  cancelContest: `${contest}/cancelContest`,
  leaderboardList: `${contest}/leaderboardList`,
  viewContestCategory: `${contest}/viewContestCategory`,
  createContestCategory: `${contest}/createContestCategory`,
  updateContestCategory: `${contest}/updateContestCategory`,
  listContestmanagement: `${contest}/listContest`,
  viewContest: `${contest}/viewContest`,
  createContest: `${contest}/createContest`,
  updateContest: `${contest}/updateContest`,

  // Order List
  orderList: `${order}/orderList`,
  viewOrder: `${order}/viewOrder`,
  updateOrder: `${order}/updateOrder`,

  // HowToWork
  detail: `${howToWork}/detail`,
  updateDetail: `${howToWork}/updateDetail`,

  // Withdraw Management
  withdrawRequestList: `${payment}/withdrawRequestList`,
  approveWithdrawRequest: `${payment}/approveWithdrawRequest`,
  getAvailablePickupAddresses: `${payment}/getAvailablePickupAddresses`,

  // add user
  addUser: `${user}/user/addUser`,
};

export default ApiConfigs;
