import React, { useEffect, useContext, useState, useMemo } from "react";
import { useLocation, matchPath, useHistory, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaWallet } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import Logo from "src/component/Logo";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,

} from "@material-ui/core";
import { AiFillBank, AiFillDashboard } from "react-icons/ai";
import { GiSkills } from "react-icons/gi";
import { MdGroupWork } from "react-icons/md";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { GrOrganization, GrDocumentConfig } from "react-icons/gr";
import { FiMenu } from "react-icons/fi";
import { FaFirstOrderAlt } from "react-icons/fa6";
import { ImUser } from "react-icons/im";
import { MdCategory } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";
import NavItem from "./NavItem";
import { GiTrophyCup } from "react-icons/gi";
import { AuthContext } from "src/context/Auth";
import { BiSitemap } from "react-icons/bi";
import { MdContentPasteGo } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
      },
      {
        title: "User Management",
        icon: ImUser,
        href: "/user-management",
      },
      {
        title: "Brand Management",
        icon: GrOrganization,
        href: "/brand-management",
      },
      {
        title: "Product Management",
        icon: MdOutlineProductionQuantityLimits,
        href: "/product-management",
      },

      {
        title: "Contest Management",
        icon: MdContentPasteGo,
        items: [
          {
            title: "Category",
            icon: MdCategory,
            href: "/Contest-management",
          },
          {
            title: "Contest",
            icon:GiTrophyCup,
            href: "/Contest",
          },
        ],
      },

      {
        title: "How To Play Management",
        icon: GiSkills,
        href: "/skill-management",
      },
      {
        title: "Banner Management",
        icon: BiSitemap,
        href: "/banner-management",
      },
      {
        title: "Content Management",
        icon: FiMenu,
        href: "/static-content-management",
      },
      {
        title: "Order Management",
        icon: FaFirstOrderAlt,
        href: "/order-management",
      },
      {
        title: "Withdraw Management",
        icon: BiMoneyWithdraw,
        href: "/Withdraw-Manage",
      },
      {
        title: "Refer & Earn Management",
        icon: MdGroupWork,
        href: "/HowTo-play",
      },
      {
        title: "Faq's Management",
        icon: FaQuestionCircle,
        href: "/faq-management",
      },
      // {
      //   title: "Logs Management",
      //   icon: GrDocumentConfig,
      //   href: "/log-management",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {
        items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )
      }
    </List >
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.header,
  },
  desktopDrawer: {
    top: "76px",
    width: "250px",
    height: "calc(100% - 115px)",
    margin: "5px 10px 10px 15px",
    background: theme.palette.background.default,

    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: "21px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);

  const logoutHandler = () => {
    window.sessionStorage.removeItem("ConnectWallet");
    window.sessionStorage.removeItem("token");
    history.push("/");
  };
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const CheckArray = useMemo(() => {
    let CheckArray;
    if (auth?.adminData?.permissions) {
      const filteredSections = sections
        .map((section) => {
          const filteredItems = section.items.filter((item) => {
            const formattedTitle = item.title.replace(/\s+/g, "").toLowerCase();
            const permissionKey = Object.keys(
              auth?.adminData?.permissions
            )?.find((key) => formattedTitle === key.toLowerCase());
            return item?.title === "Dashboard"
              ? item
              : auth?.adminData?.permissions[permissionKey] === true;
          });
          if (filteredItems.length > 0) {
            return { ...section, items: filteredItems };
          } else {
            return null; // Return null for sections with no matching items
          }
        })
        .filter(Boolean);

      CheckArray =
        auth?.adminData?.userType === "ADMIN" ? sections : filteredSections;
    }
    return CheckArray;
  }, [sections, auth?.adminData?.permissions]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {sections &&
              sections?.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    img: section.img,
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <Box style={{ position: "absolute", top: "0", right: "0" }}>
              <IconButton onClick={() => setIsLogout(false)}>
                <GrFormClose />
              </IconButton>
            </Box>
            
            <DialogContent>
            <Typography variant="h4" color="primary" align="center">
                  Logout
                </Typography>
              <Box className={classes.dialougTitle} align="center" style={{marginTop:"20px"}}>
                <Typography variant="h6" color="primary">
                  Are you sure you want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={logoutHandler}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {" "}
          {/* <Hidden lgUp>
            <Box className="leftBox">
              <Link to="/">
                <Logo width="125" />
              </Link>
            </Box>
          </Hidden> */}
          <Box>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
