import axios from "axios";
import Apiconfigs from "../ApiConfig";

export const apiRouterCall = async ({
  method,
  endPoint,
  bodyData,
  paramsData,
  id,
  token,
}) => {
  try {
    const res = await axios({
      method: method,
      url: id ? `${Apiconfigs[endPoint]}/${id}` : Apiconfigs[endPoint],
      headers: {
        token: window?.sessionStorage?.getItem("token"),
      },
      params: paramsData ? paramsData : null,
      data: bodyData ? bodyData : null,
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
